.site-layout-content {
    min-height: 100vh;
    padding: 16px;
    background: #fff;
}

.steps-content {
    min-height: 280px;
    margin-top: 16px;
    text-align: center;
    background-color: #fafafa;
    border: 1px dashed #e9e9e9;
    border-radius: 2px;
}

#components-layout-demo-fixed .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    background: rgba(255, 255, 255, 0.2);
}
.site-layout .site-layout-background {
    background: #fff;
}

.red-header{
    background-color: #f48fb1;
}